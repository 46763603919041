<template>
  <div class="wrap__content">
    <div class="content__page content__page--margintop">
      <div class="container">
        <div class="section__title">
          <strong>{{ $t('about.title') }}</strong>
        </div>

        <div class="section--desc">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Accusantium cum ea excepturi facere repudiandae sunt tempore
            tenetur. Amet deleniti expedita laborum natus officia sed tempore
            tenetur vitae? Dolore, nesciunt qui.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Accusantium cum ea excepturi facere repudiandae sunt tempore
            tenetur. Amet deleniti expedita laborum natus officia sed tempore
            tenetur vitae? Dolore, nesciunt qui.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Accusantium cum ea excepturi facere repudiandae sunt tempore
            tenetur. Amet deleniti expedita laborum natus officia sed tempore
            tenetur vitae? Dolore, nesciunt qui.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Accusantium cum ea excepturi facere repudiandae sunt tempore
            tenetur. Amet deleniti expedita laborum natus officia sed tempore
            tenetur vitae? Dolore, nesciunt qui.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Accusantium cum ea excepturi facere repudiandae sunt tempore
            tenetur. Amet deleniti expedita laborum natus officia sed tempore
            tenetur vitae? Dolore, nesciunt qui.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Accusantium cum ea excepturi facere repudiandae sunt tempore
            tenetur. Amet deleniti expedita laborum natus officia sed tempore
            tenetur vitae? Dolore, nesciunt qui.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
  head: {
    title() {
      return {
        inner:
          this.$i18n.locale == 'kz'
            ? 'Модуль туралы  | «eQonaq» мамандандырылған ақпараттық жүйесі'
            : this.$i18n.locale == 'ru'
              ? 'О модуле | Специализированная информационная система «eQonaq»'
              : 'About the module | Specialized information system "eQonaq"',
      }
    },
    meta: [{ name: 'description', content: 'My description' }],
  },
}
</script>

<style scoped></style>
